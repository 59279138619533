import { css } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { NavLink as UnstyledNavLink } from 'react-router-dom';

const NavLink = styled(UnstyledNavLink)(
  ({ theme, compact }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    ${compact ? 'padding: 0 1.5rem;' : 'width: 12rem;'}
    font-weight: bold;

    &.active,
    &:hover {
      background-color: ${theme.primary};
    }
  `
);

NavLink.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
};

export default NavLink;
