import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { faBook, faCalendarDay, faCity, faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import reportIcon from '../../../assets/icons/edit.svg';
import homeIcon from '../../../assets/icons/home.svg';
import { ICON_CHART_BAR, ICON_TACHOMETER } from '../../../fontLibrary';
import {
  PATH_ANALYTICS,
  PATH_BIOS,
  PATH_COMPANIES,
  PATH_CONTROL_PANEL,
  PATH_DASHBOARD,
  PATH_KPI_AND_TICKERS,
  PATH_ME_EDIT,
  PATH_PODCASTS,
  PATH_REPORTS,
  PATH_SCHEDULE,
} from '../../../router/paths';
import { useAllowedNavItems } from '../../../utils/nav';
import NavLink from './NavLink/NavLink';

const StyledNavbar = styled.nav(
  ({ theme }) => css`
    height: 2.5rem;
    background-color: ${theme.navBackground};
    color: white;
    display: flex;
  `
);

export const navItems = [
  {
    title: 'Dashboard',
    to: PATH_DASHBOARD,
    image: homeIcon,
  },
  {
    title: 'Control Panel',
    to: PATH_CONTROL_PANEL,
    image: reportIcon,
  },
  {
    title: 'Reports',
    to: PATH_REPORTS,
    image: reportIcon,
  },
  {
    title: 'Analytics',
    to: PATH_ANALYTICS,
    image: ICON_CHART_BAR,
  },
  {
    title: 'KPI and Tickers',
    to: PATH_KPI_AND_TICKERS,
    image: ICON_TACHOMETER,
  },
  {
    title: 'Companies',
    to: PATH_COMPANIES,
    image: faCity,
  },
  {
    title: 'Podcasts',
    to: PATH_PODCASTS,
    image: faMicrophone,
  },
  {
    title: 'Bio',
    to: PATH_BIOS,
    image: faBook,
  },
  {
    title: 'Release Calendar',
    to: PATH_SCHEDULE,
    image: faCalendarDay,
  },
  {
    title: 'Change Password',
    to: PATH_ME_EDIT,
    onlyIfActive: true,
  },
];

const NavImage = styled.img`
  margin-right: 1rem;
`;

function imageIconToMarkup(imageIcon) {
  if (typeof imageIcon === 'object') {
    return <FontAwesomeIcon style={{ marginRight: '1rem', fontSize: '1.4em' }} icon={imageIcon} />;
  }

  if (imageIcon) {
    return <NavImage src={imageIcon} alt="icon" />;
  }

  return null;
}

function Navbar() {
  const allowedNavItems = useAllowedNavItems();

  return (
    <StyledNavbar className="layoutNavbar">
      {allowedNavItems.map(({ to, title, image }, index) => (
        <NavLink
          key={index}
          to={to}
          exact={to === '/'}
          compact={allowedNavItems.length >= 8 ? 'true' : ''}
        >
          {imageIconToMarkup(image)}
          {title}
        </NavLink>
      ))}
    </StyledNavbar>
  );
}

export default Navbar;
