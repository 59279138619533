import { noop } from 'lodash-es';
import React, { ComponentProps, CSSProperties, useMemo } from 'react';
import ReactSelect from 'react-select';
import Creatable from 'react-select/creatable';
import {
  isNewValueRepresentation,
  NewValueRepresentation,
  toNewValueRepresentation,
} from '../../../store/representations/newValue';
import { darkerGray, lightBlue } from '../../../styles/variables';
import FieldErrors from '../../FieldError/FieldErrors';
import FloatingPlaceholder from '../FakePlaceholder/FloatingPlaceholder';
import { reportInputHeight } from '../../ReportEditPage/reportEditPageCss';

/**
 * react-select has the value of a field wrapped in {value: 'somevalue'}. This function extracts it.
 * @param {object} value
 * @return {string}
 */
function extractSelectValue<T>({ value }: SelectOption<T>) {
  return value;
}

type SelectOption<T> = { label: string | number; value: T };

type Value<T> = T | NewValueRepresentation;

/**
 * Simpler interface to react-select.
 *
 * @param props {Object}
 * @param props.staticPositionedMenu Whether the menu should be statically positioned.
 */
function Select<T>({
  isMulti,
  options = [],
  value,
  onChange = noop,
  error,
  staticPositionedMenu,
  noMinHeight,
  containerStyle = {},
  creatable,
  colorMap = {},
  ...restProps
}: Props<T>) {
  options = useMemo(() => {
    return !isMulti && isNewValueRepresentation(value)
      ? [{ label: value.value, value }, ...options]
      : options;
  }, [isMulti, options, value]);

  if (creatable && isMulti) {
    throw new Error('Creatable multi-select is not currently supported.');
  }

  // react-select expects a value to be a {label, value} object, or an array of such.
  const reactSelectValue = useMemo(
    () =>
      value
        ? isMulti
          ? options.filter(option =>
              (Array.isArray(value) ? value : []).includes(option.value as T)
            )
          : options.find(option => option.value === value)
        : null,
    [isMulti, options, value]
  );

  // For select, we must destructure an object with a "value" key containing the actual value.
  const reactSelectOnChange = input =>
    onChange(isMulti ? (input || []).map(extractSelectValue) : extractSelectValue(input));

  const colorStyleFn = (styles, { data }) => ({
    ...styles,
    color: colorMap[data.value],
    opacity: data.isDisabled ? '0.5' : 1,
  });
  const commonProps: ComponentProps<typeof ReactSelect> = {
    isMulti,
    options,
    value: reactSelectValue,
    onChange: reactSelectOnChange,
    styles: {
      option: colorStyleFn,
      singleValue: colorStyleFn,
      container: (provided, state) => ({
        ...provided,
        opacity: state.isDisabled ? 0.5 : 1,
      }),
      control: (provided, state) => ({
        ...provided,
        borderWidth: 0,
        borderBottomWidth: '1px',
        boxSizing: 'border-box',
        borderColor: state.isFocused ? lightBlue : darkerGray,
        borderRadius: 0,
        backgroundColor: 'transparent',
        boxShadow: state.isFocused ? `0 1px 0 0 ${lightBlue}` : 'none',
        '&:hover': {
          borderColor: state.isFocused ? lightBlue : 'hsl(0,0%,70%)',
        },
        cursor: creatable ? 'text' : void 0,
      }),
      indicatorSeparator: provided => ({
        ...provided,
        visibility: 'hidden',
      }),
      valueContainer: provided => ({
        ...provided,
        paddingLeft: 0,
        fontWeight: 'bold',
        minHeight: noMinHeight ? 0 : reportInputHeight,
        paddingTop: '0.7em',
      }),
      menu: provided => ({
        ...provided,
        ...(staticPositionedMenu ? { position: 'static' } : {}),
        zIndex: 5,
      }),
    },
    ...restProps,
  };

  return (
    <div>
      <FloatingPlaceholder value={value} placeholder={restProps.placeholder} />
      {creatable && !isMulti ? (
        <Creatable
          {...commonProps}
          onCreateOption={text => {
            (onChange as (value: Value<T>) => void)(toNewValueRepresentation(text));
          }}
        />
      ) : (
        <ReactSelect {...commonProps} />
      )}
      <FieldErrors errors={error} />
    </div>
  );
}

type Props<T> = {
  error?: string | string[];
  options: SelectOption<Value<T>>[];
  isMulti?: boolean;
  value: any;
  onChange: (any) => void;
  staticPositionedMenu?: boolean;
  noMinHeight?: boolean;
  containerStyle?: CSSProperties;
  creatable?: boolean;
  colorMap?: { [key: string]: string };
  placeholder?: string;
  isDisabled?: boolean;
};

export default Select;
